<template lang="">
    <div>
        <b-card header="ສະແກນ QR">
            <!-- <b-form-group>
                            <b-form-input v-model="qrcode" v-on:keyup.enter="onEnterEnd" ref="qrcode"
                                placeholder="QR Code......"></b-form-input>
                        </b-form-group> -->
            <b-input-group class="mt-3">
                <b-form-input v-model="qrcode" v-on:keyup.enter="onEnterEnd" ref="qrcode" placeholder="QR Code......"></b-form-input>
                <b-input-group-append>
                    <b-button variant="outline-success" to="/qrscan">
                        <b-icon icon="camera"></b-icon>
                    </b-button>
    
                </b-input-group-append>
            </b-input-group>
        </b-card>
       
        <b-card header="ຄົ້ນຫາ...." class="m-3">
            <b-row>
                <b-col>
                    <b-form-group label-cols="4" label-cols-lg="2" label-size="lg" label="ຊື່:" label-for="input-lg">
                        <b-form-input v-model="fname"  size="lg"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="lg" label="ນາມສະກຸນ:" label-for="input-lg">
                        <b-form-input  v-model="lname"  size="lg"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-button variant="primary" @click="onFinditems" size="lg"> <b-icon icon="search"></b-icon> ຊອກຫາ....</b-button>
                </b-col>
            </b-row>
        </b-card>


        <b-list-group class="m-3" >
            <b-list-group-item >
                 <b-row>
                    <b-col md="1">ລຳດັບ</b-col>
                    <b-col md="2">ຊື່ ແລະ ນາມສະກຸນ</b-col>
                    <!-- <b-col md="2">ວັນເດືອນປີເກິດ, ອາຍຸ</b-col> -->
                    <b-col md="3">ວັນທີ, ເວລານັດ</b-col>
                    <b-col md="1">QR</b-col>
                    <b-col  >ສະຖານະ</b-col>
                </b-row>
            </b-list-group-item>
           
            <b-list-group-item  v-for="(item,index) in items" :key="item.queue_id">
                
                <b-row>
                    <b-col md="1"> {{index+1}} </b-col>
                    <b-col md="2">{{item.fname}} {{item.lname}}</b-col>
                    <!-- <b-col md="2">{{item.dob |formatDate }}, {{item.age}} ປີ</b-col> -->
                    <b-col md="3" class="text-success">{{item.queue_date | dateString }} </b-col>
                    <b-col md="1"> 
                        <!-- <b-link :to="'/qr/'+item.queue_id">QR</b-link> <b-icon src="../assets/icons8-qr-code-48.png"></b-icon>  -->
                        <b-link  @click="gotoqr(item.queue_id)" >QR</b-link> <b-icon src="../assets/icons8-qr-code-48.png"></b-icon>
                    </b-col>
                    <b-col>{{item.status}}</b-col>
                </b-row>
            </b-list-group-item>
        </b-list-group>

        
        
    </div>
</template>

<script>
import axios from "axios";
export default {
    mounted() {
        this.$refs.qrcode.$el.focus()

        let login = localStorage.getItem("Login-qr");
        this.user = JSON.parse(login);


        let iSlogin = false
        try {
            let Login = JSON.parse(localStorage.getItem('Login-qr'))
            // console.log(Login.Auth_Login)

            if (Login == null) {
                iSlogin = false
            } else {
                iSlogin = Login.Auth_Login
            }
        } catch (error) {
            iSlogin = false
            // console.log(error)
        }

        if (iSlogin == true) {
            this.$router.push('/qrscanqr')
        } else {
            this.$router.push('/qradmin/login')
        }

    },

    methods: {
        onEnterEnd() {
            // if()
            this.$router.push('/queueinfo2?qr=' + this.qrcode)
        },
        onFinditems() {

            axios
                .get(
                    this.$store.getters.getBaseUrl +
                    "/findqueue?fname=" + this.fname + "&lname=" + this.lname, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
                        "Accept-Encoding": "identity"
                    }
                }
                )
                .then((response) => {
                    this.items = response.data.items;
                })
        },
        gotoqr(queu_id) {
            axios
                .get(
                    this.$store.getters.getBaseUrl + "/v_queue_details/queue_id/" + queu_id, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
                        "Accept-Encoding": "identity"
                    }
                }
                )
                .then((response) => {
                    // JSON responses are automatically parsed.
                    this.fld = response.data[0]
                    //  let  qr= [this.fld.queue_id, this.fld.queue_no, this.fld.fname, this.fld.lname, this.fld.age, this.fld.sex, this.fld.dob, this.fld.queue_date,this.fld.start_time,this.fld.end_time, this.fld.ca1, this.fld.ca2, this.fld.ca3, this.fld.ca4, this.fld.ca5, this.fld.ca6, this.fld.ca7, this.fld.ca8, this.fld.ca9, this.fld.ca10, this.fld.ca11, this.fld.ca12, this.fld.ca13, this.fld.ca14, this.fld.ca15]
                    //  let qr = [this.fld.queue_id, this.fld.queue_no, this.fld.fname, this.fld.lname, this.fld.age, this.fld.sex, this.fld.dob, this.fld.queue_date, this.fld.start_time, this.fld.end_time, this.fld.ca1]

                    // this.qr_code = qr.join("|")


                    localStorage.setItem("qr_code", JSON.stringify(this.fld))
                    this.$router.push({ path: '/qr/' + queu_id });

                })


        },
    },
    data() {
        return {
            fld: [],
            qrcode: "",
            user: [],
            items: [],
            fname: "",
            lname: "",
        }
    },

}
</script>

<style lang="">

</style>